<template>
  <div>
    <h1 class="mt-4 mb-4">Panel de control</h1>

    <p>Bienvenido al panel de control de usuario de Bemag.</p>
    <p>Aquí puedes administrar tu usuario y hacer solicitudes. Para acceder a la plataforma y ver contenido tienes que dirigirte a: <button onclick="window.location.href='https://bemag.app'" class="btn m-1 btn-success">bemag.app</button></p>
    <h3 class="mt-4"><i class="fas fa-bell"></i> - Notificaciones</h3>

    <p v-show="errorNotifications">Error obteniendo las notificaciones</p>

    <h3 v-show="loadingNotifications" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorNotifications && !loadingNotifications">
      No tienes notificaciones
    </div>

    <h3 class="mt-4"><i class="fas fa-tachometer-alt"></i> - Dispositivos activos ({{ sessions.length }}) <button type="button" class="btn m-1 btn-outline-success" v-on:click="getSessions()"><i class="fas fa-sync-alt"></i></button></h3>

    <p v-show="errorSessions">Error obteniendo los dispositivos activos</p>

    <h3 v-show="loadingSessions" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!loadingSessions && !errorSessions" class="row">

      <div class="col-sm-auto border m-3 shadow bg-light" v-for="session in sessions" :key="session.Id">

        <div v-if="perfil.admin">
          <AdminSessionCard :session="session"></AdminSessionCard>
        </div>

        <div v-else>
          <UserSessionCard :session="session"></UserSessionCard>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import UserSessionCard from '../components/UserSessionCard.vue'
  import AdminSessionCard from '../components/AdminSessionCard.vue'
  import config from '../config'

  export default {
    data() {
      return {
        sessions: [],
        notifications: [],
        loadingSessions: true,
        loadingNotifications: true,
        errorNotifications: false,
        errorSessions: false
      }
    },
    computed: {
      perfil() {
        return this.$store.state.perfil
      }
    },
    methods: {
      async getSessions() {
        try {
          this.loadingSessions = true
          const res = await axios.get(config.apiUrl + '/emby/sesiones')
          this.loadingSessions = false
          this.sessions = res.data
        } catch (e) {
          this.loadingSessions = false
          this.errorSessions = true
        }
      },
      async getNotifications() {
        try {
          const res = await axios.get(config.apiUrl + '/notificaciones')
          this.loadingNotifications = false
          this.notifications = res.data
        } catch (e) {
          this.loadingNotifications = false
          this.errorNotifications = true
        }
      }
    },
    components: {
      UserSessionCard,
      AdminSessionCard
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getSessions()
      this.getNotifications()
    }
  }
</script>
