<template>
  <div class="card-body text-center">

    <h5>
      <router-link :to="{ name: 'Usuario', params: { embyId: session.UserId } }" class="not-active">
        <i class="fas fa-user"></i> {{ session.UserName }}
      </router-link>
    </h5>
    <p> <img :src="session.AppIconUrl" class="img-fluid" style="max-width: 20px"> {{ session.DeviceName }} - {{ session.Client }} ({{ session.ApplicationVersion }})</p>
    <hr>

    <div class="text-left">
      <div v-if="session.NowPlayingItem">
        <div v-if="session.NowPlayingItem.Type === 'Episode'">
          <div class="text-center">
            <img :src="'https://bemag.app/Items/' + session.NowPlayingItem.SeriesId + '/Images/Primary?tag=' + session.NowPlayingItem.SeriesPrimaryImageTag + '&maxWidth=150'" class="rounded">
          </div>
          <p class="mt-3"><i class="fas fa-tv"></i> {{ session.NowPlayingItem.SeriesName }} - S{{ session.NowPlayingItem.ParentIndexNumber }}:E{{ session.NowPlayingItem.IndexNumber }} - {{ session.NowPlayingItem.Name }}</p>
        </div>

        <div v-else>
          <div class="text-center">
            <img :src="'https://bemag.app/Items/' + session.NowPlayingItem.Id + '/Images/Primary?tag=' + session.NowPlayingItem.ImageTags.Primary + '&maxWidth=150'" class="rounded">                      </div>
          <p class="mt-3"><i class="fas fa-film"></i> {{ session.NowPlayingItem.Name }}</p>
        </div>

        <p><i class="fas fa-play"></i> {{ session.NowPlayingItem.MediaStreams[0].DisplayTitle }} - {{ session.PlayState.PlayMethod }}</p>
      </div>

      <p>
        <i class="fas fa-map-marker-alt"></i>
        <i v-show="loadingLocation" class="fas fa-circle-notch fa-spin ml-1"></i>
        <a v-show="!loadingLocation" class="ml-1" href="#" @click.prevent="getLocation(session.RemoteEndPoint)">{{ session.RemoteEndPoint }}</a>
      </p>

      <div v-if="session.NowPlayingItem === undefined">
        <p><i class="far fa-clock"></i> {{ session.ultimaActividad }}</p>
      </div>

      <div class="text-center" v-if="session.SupportedCommands.length > 0">
        <img v-show="sendingMessage" src="../assets/images/cargando.gif" style="max-width: 20px">
        <button v-show="!sendingMessage" type="button" class="btn m-1 btn-outline-info msg" @click="sendMessage(session.Id)"><i class="fas fa-comment-alt"></i></button>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import Swal from 'sweetalert2'

  export default {
    props: ['session'],
    data() {
      return {
        sendingMessage: false,
        loadingLocation: false
      }
    },
    methods: {
      async sendMessage(sessionId) {
        try {
          const { value: mensaje} = await Swal.fire({
            title: 'Escribe el mensaje',
            input: 'text',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return '¡Escribe algún mensaje!'
              }
            }
          })

          if (!mensaje) {
            return
          }

          this.sendingMessage = true

          const res = await axios.post(config.apiUrl + '/emby/mensaje', {
            mensaje,
            sessionId
          })

          this.sendingMessage = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

        } catch (e) {
          this.sendingMessage = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async getLocation(ip) {
        try {
          this.loadingLocation = true

          const res = await axios.get(config.apiUrl + '/ubicacion/' + ip)

          this.loadingLocation = false

          Swal.fire({
            text: `${res.data.city}, ${res.data.regionName}, ${res.data.country}`,
          })
        } catch (e) {
          this.loadingLocation = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
