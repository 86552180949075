<template>
  <div class="card-body text-center">

    <h5><img :src="session.AppIconUrl" class="img-fluid" style="max-width: 20px"> {{ session.DeviceName }} - {{ session.Client }}</h5>
    <hr>

    <div class="text-left">
      <div v-if="session.NowPlayingItem">
        <div v-if="session.NowPlayingItem.Type === 'Episode'">
          <div class="text-center">
            <img :src="'https://bemag.app/Items/' + session.NowPlayingItem.SeriesId + '/Images/Primary?tag=' + session.NowPlayingItem.SeriesPrimaryImageTag + '&maxWidth=150'" class="rounded">
          </div>
          <p class="mt-3"><i class="fas fa-tv"></i> {{ session.NowPlayingItem.SeriesName }} - S{{ session.NowPlayingItem.ParentIndexNumber }}:E{{ session.NowPlayingItem.IndexNumber }} - {{ session.NowPlayingItem.Name }}</p>
        </div>

        <div v-else>
          <div class="text-center">
            <img :src="'https://bemag.app/Items/' + session.NowPlayingItem.Id + '/Images/Primary?tag=' + session.NowPlayingItem.ImageTags.Primary + '&maxWidth=150'" class="rounded">                      </div>
          <p class="mt-3"><i class="fas fa-film"></i> {{ session.NowPlayingItem.Name }}</p>
        </div>

        <p><i class="fas fa-play"></i> {{ session.NowPlayingItem.MediaStreams[0].DisplayTitle }} - {{ session.PlayState.PlayMethod }}</p>
      </div>

      <p>
        <i class="fas fa-map-marker-alt"></i>
        <i v-show="loadingLocation" class="fas fa-circle-notch fa-spin ml-1"></i>
        <a v-show="!loadingLocation" class="ml-1" href="#" @click.prevent="getLocation(session.RemoteEndPoint)">{{ session.RemoteEndPoint }}</a>
      </p>

      <div v-if="session.NowPlayingItem === undefined">
        <p v-if="session.ultimaActividad === 'Hace 0 minuto/s'"><span class="badge badge-success">Online</span></p>

        <p v-else>{{ session.ultimaActividad }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import Swal from 'sweetalert2'

  export default {
    props: ['session'],
    data () {
      return {
        loadingLocation: false
      }
    },
    methods: {
      async getLocation(ip) {
        try {
          this.loadingLocation = true

          const res = await axios.get(config.apiUrl + '/ubicacion/' + ip)

          this.loadingLocation = false

          Swal.fire({
            text: `${res.data.city}, ${res.data.regionName}, ${res.data.country}`,
          })
        } catch (e) {
          this.loadingLocation = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
